import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const WorkCardContainer = styled.li`
  a {
    color: white;
    text-decoration: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;
    .thumbnail__img {
      height: 216px;
      @media screen and (max-width: 970px) {
        height: 140px;
      }
      isolation: isolate;
      border-radius: 16px;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 100%;
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .thumbnail__text {
      width: 100%;
      margin-left: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      .genre {
        font-size: 1rem;
        font-weight: 700;
        padding: 2px 1rem;
        border: 2px solid white;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
`;

const WorkCard = ({ title, genre, description, imgPath, slug }) => {
  return (
    <WorkCardContainer>
      <Link to={`/works/${slug}`}>
        <div className="thumbnail__img">
          <GatsbyImage image={imgPath} alt={title}></GatsbyImage>
        </div>
        <div className="thumbnail__text">
          <span className="genre">{genre}</span>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </Link>
    </WorkCardContainer>
  );
};

export default WorkCard;

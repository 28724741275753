import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import Header from "../components/Header";
import Footer from "../components/Footer";
import WorkCard from "../components/Workcard";

const MainContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

const WorksContainer = styled.ul`
  margin-top: 10vh;
  padding: 0 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 32px;
  @media screen and (max-width: 970px) {
    grid-template-columns: 1fr;
  }
`;

const Works = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "MMMM D, YYYY")
              genre
              shortDescription
              thumbnailPath {
                childImageSharp {
                  gatsbyImageData
                }
              }
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const worksData = data.allMarkdownRemark.edges;

  return (
    <>
      <MainContents>
        <WidthLimiter>
          <Header pageTitle="Works"></Header>
          <WorksContainer>
            {worksData.map((work) => (
              <WorkCard
                key={work.node.id}
                title={work.node.frontmatter.title}
                genre={work.node.frontmatter.genre}
                description={work.node.frontmatter.shortDescription}
                imgPath={
                  work.node.frontmatter.thumbnailPath.childImageSharp
                    .gatsbyImageData
                }
                slug={work.node.fields.slug}
              ></WorkCard>
            ))}
          </WorksContainer>
          <Footer></Footer>
        </WidthLimiter>
      </MainContents>
    </>
  );
};

export default Works;
